import React, { useRef, useEffect, useState } from "react";
import './App.css';

import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, split, useLazyQuery } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { gql, useSubscription } from '@apollo/client';

import dryer from './images/dryer.png';
import dryerframeIdle from './images/dryerframeidle.png';
import dryerframeRunning from './images/dryerframerunning.png';
import dryerDoor from './images/dryerdoor.png';
import washer from './images/washer.png';
import washerframeIdle from './images/washerframeidle.png';
import washerframeRunning from './images/washerframerunning.png';
import washerDoor from './images/washerdoor.png';

const now = new Date();

// Apollo client
const adminSecret = process.env.REACT_APP_HASURA_ADMIN_SECRET;

const headers = {
  "x-hasura-admin-secret": adminSecret
};

const httpLink = new HttpLink({
  uri: 'https://effinger-woesch.hasura.app/v1/graphql',
  headers: headers
});

const wsLink = new WebSocketLink({
  uri: 'wss://effinger-woesch.hasura.app/v1/graphql',
  options: {
    timeout: 15000,
    reconnect: true,
    lazy: true,
    connectionParams: {
      headers: headers
    }
  }
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
});

const liveSubscriptionQuery = gql`
  subscription {
    woesch_live(
    order_by: { id: desc }
    limit: 1
    ){
      id
      leftoutbottom
      leftmiddlebottom
      rightmiddlebottom
      rightoutbottom
      leftouttop
      leftmiddletop
      rightmiddletop
      rightouttop
    }
  }
`;

const averageQuery = gql`
  query {
    woesch_history(
      where: {
        averagewasherruntime: { _is_null: false },
        averagedryerruntime: { _is_null: false }
      }
      order_by: { id: desc }
      limit: 1
      ){
        id
        averagewasherruntime
        averagedryerruntime
    }
  }
`;

const lookBackWeeks = 1;
const deviceNumber = 1;
const cacheExpirySeconds = 604800 * lookBackWeeks;
const historyQuery = gql`
  query {
    woesch_history(
    where: {
      start: { _is_null: false },
      finish: { _is_null: false, _gte: "${Math.round(now / 1000) - cacheExpirySeconds}"}
    }
    order_by: { id: desc }
    ){
      id
      start
      finish
    }
  }
`;

function App() {
  const { data: lData} = useSubscription(liveSubscriptionQuery); 
  const liveData = useRef({});
  const [liveDataState, setliveDataState] = useState({});
  const [fetchAverageData, { data: aData }] = useLazyQuery(averageQuery);
  const [fetchHistoryData, { data: hData }] = useLazyQuery(historyQuery);
  const averageAndHistoryFetched = useRef(false);
  const [websocketStatus, setwebsocketStatus] = useState(1);
  const timeElapsed = useRef({});
  const [timeElapsedState, settimeElapsedState] = useState({});
  const averageEstimate = useRef({});
  const [averageEstimateState, setaverageEstimateState] = useState({});
  const [clockRotationDeg, setclockRotationDeg] = useState(0);
  const [sliceOpacities, setsliceOpacities] = useState([]);
  const [historyPulseOpacities, sethistoryPulseOpacities] = useState([]);
  const [weekSliceOpacities, setweekSliceOpacities] = useState(Array(7).fill(Array(48).fill(0)));
  const weekSliceOpacitiesSet = useRef(false);

  useEffect(() => {
    let timer;
    if (websocketStatus !== 0 && (!lData || (!aData && averageAndHistoryFetched.current) || (!hData && averageAndHistoryFetched.current))) {
      timer = setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      clearTimeout(timer);
    }
    return () => {
      if (timer) clearTimeout(timer);
    }
  }, [websocketStatus, lData, aData, hData]);

  useEffect(() => {
    if (lData) {
      liveData.current = lData.woesch_live[0];
      setliveDataState(liveData.current);
    }
  }, [lData]);
  
  const averageEstimateTimeoutId = useRef(null);
  useEffect(() => {
    if (aData) {
      if (!aData.woesch_history.length) return;
      averageEstimate.current = aData.woesch_history[0];
      averageEstimateTimeoutId.current = setTimeout(() => {
        setaverageEstimateState({
          averagewasherruntime: averageEstimate.current?.averagewasherruntime ? getTimeStamp(averageEstimate.current.averagewasherruntime) : 0,
          averagedryerruntime: averageEstimate.current?.averagedryerruntime ? getTimeStamp(averageEstimate.current.averagedryerruntime) : 0
        });
      }, 500);
      localStorage.setItem(averageEstimateCacheKey, JSON.stringify(aData.woesch_history[0]));
    }
    return () => {
      if (averageEstimateTimeoutId.current) clearTimeout(averageEstimateTimeoutId.current);
    };
  }, [aData]);

  const weekSliceOpacitiesTimeoutId = useRef(null);
  useEffect(() => {
    if (hData) {
      if (!hData.woesch_history.length) return;
      const weekSeconds = Array(7).fill(null).map(() => Array(48).fill(0)); // Initialize 7x48 array for active seconds
  
      hData.woesch_history.forEach(({ start, finish }) => {
        if (!start || !finish || finish <= start) return; // Skip incompatible entries
        let startTime = new Date(start * 1000); // Convert unix timestamp to Date object
        let finishTime = new Date(finish * 1000);
  
        while (startTime < finishTime) {
          // Calculate half-hour block index within the day (0-47)
          const halfHourIndex = Math.floor((startTime.getHours() * 60 + startTime.getMinutes()) / 30);
          
          // Move the curser to the next half hour block start. This means setting the minutes to either 0 or 30. Hours and Day remain the same.
          const nextBlockStartTime = new Date(startTime);
          nextBlockStartTime.setMinutes(Math.floor(startTime.getMinutes() / 30) * 30 + 30);
          nextBlockStartTime.setSeconds(0);
          
          // Get the Seconds to add to the current block to arrive at the next block start or the finish time (min between next block time or finish time so not too much seconds are added all at once)
          const secondsToAdd = Math.min((finishTime - startTime) / 1000, (nextBlockStartTime - startTime) / 1000);
          
          // Get the day of the week (0 = Sunday, 6 = Saturday)
          const dayOfWeek = startTime.getDay();

          // Add the active seconds to the corresponding half-hour block of the week
          weekSeconds[dayOfWeek][halfHourIndex] += secondsToAdd;
  
          // Move the start time to the next block
          startTime = nextBlockStartTime;
        }
      });

      const weekOpacities = weekSeconds.map(dayData =>
        dayData.map(seconds => Math.round((seconds / (lookBackWeeks * deviceNumber * 1800)) * 1000) / 1000) // map and normalize seconds to opacity level based on lookbackweeks and number of devices and round to 3 decimal places
      );

      setweekSliceOpacities(weekOpacities);
      weekSliceOpacitiesTimeoutId.current = setTimeout(() => {
        weekSliceOpacitiesSet.current = true;
      }, 1000);
      localStorage.setItem(weekSliceOpacitiesCacheKey, JSON.stringify(weekOpacities));
    }
    return () => {
      if (weekSliceOpacitiesTimeoutId.current) {
        clearTimeout(weekSliceOpacitiesTimeoutId.current);
      }
    };
  }, [hData]);

  const weekdays = ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'];
  const weekdaysMask = [...weekdays, ...weekdays, ...weekdays];
  const dayKeys = {'SO': 0, 'MO': 1, 'DI': 2, 'MI': 3, 'DO': 4, 'FR': 5, 'SA': 6};
  const rushclockRef = useRef();
  const circleInCircleRef = useRef();
  const weekdayRefs = useRef([]);
  const weekdaysRef = useRef();
  const scrollValue = useRef(0);
  const scrollValueTarget = useRef();
  const scrollValueSettleRequest = useRef(false);
  const relativeDistances = useRef({});
  const day = useRef();
  const conicGradientOpacity = useRef(1);
  const targetConicGradientOpacity = useRef(1);
  
  const getCurrentDayAndOpacity = () => {
    const circle = circleInCircleRef.current.getBoundingClientRect();
    const circleWidth = circle.width;
    const circleHeight = circle.height;

    weekdayRefs.current.forEach((weekday, index) => {
      if (weekday) {
        const weekdayRect = weekday.getBoundingClientRect();
        const relativeX =
          (weekdayRect.left + weekdayRect.width / 2 - circle.left) /
          circleWidth;
        const relativeY =
          (weekdayRect.top + weekdayRect.height / 2 - circle.top) /
          circleHeight;
        const relativeDistance = Math.sqrt(
          Math.pow(relativeX - 0.5, 2) + Math.pow(relativeY - 0.5, 2)
        );
        relativeDistances.current[index + weekday.innerText] = relativeDistance;
        day.current = dayKeys[
          Object.keys(relativeDistances.current).reduce((a, b) => 
          relativeDistances.current[b] < relativeDistances.current[a] ? b : a)
          .slice(-2)
        ];
        weekday.style.opacity = 1 - relativeDistance*3.125;
      }
    });
  };

  useEffect(() => {
    getCurrentDayAndOpacity();
    if (Math.abs(scrollValue.current) >= horizontalScrollStep * 7) scrollValue.current = 0;
    targetConicGradientOpacity.current = Math.abs(Math.cos(((scrollValue.current % horizontalScrollStep) / horizontalScrollStep) * Math.PI)) * 0.5;
  }, [scrollValue.current]); // eslint-disable-line react-hooks/exhaustive-deps

  const timeouts = useRef([]);
  useEffect(() => {
    setsliceOpacities(Array(48).fill(0));
    if (isWeekDayClick.current && !isWeekDayScrolling.current) {
      timeouts.current.forEach(clearTimeout);
      timeouts.current = [];
      setsliceOpacities(weekSliceOpacities[day.current]);
    } else {
      weekSliceOpacities[day.current].forEach((opacity, index) => {
        const timeoutId = setTimeout(() => {
          setsliceOpacities((prevOpacities) => {
            const newOpacities = [...prevOpacities];
            newOpacities[index] = opacity;
            return newOpacities;
          });
        }, index * 20);
        timeouts.current.push(timeoutId);
      });
    }
  }, [day.current, weekSliceOpacities]); // eslint-disable-line react-hooks/exhaustive-deps

  const timeoutRef = useRef(null);
  useEffect(() => {
    sethistoryPulseOpacities(Array(48).fill(0));
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      sethistoryPulseOpacities(sliceOpacities);
    }, 40);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [sliceOpacities]);

  const darkmode = useRef(true);
  function setDarkmode(on) {
    const body = document.querySelector('body');
    if (on) body.style.backgroundColor = 'rgba(0, 0, 0, 0.75)';
    else body.style.backgroundColor = 'white';
  };

  useEffect(() => {
    setDarkmode(darkmode.current);
  }, [darkmode.current]); // eslint-disable-line react-hooks/exhaustive-deps

  function toggleDarkmode(event) {
    const circleInCircleBounds = circleInCircleRef.current.getBoundingClientRect();
    const weekdaysBounds = weekdaysRef.current.getBoundingClientRect();
    const isInsideWeekdays = 
      event.clientX >= circleInCircleBounds.left &&
      event.clientX <= circleInCircleBounds.right &&
      event.clientY >= weekdaysBounds.top &&
      event.clientY <= weekdaysBounds.bottom;
    if (isInsideWeekdays) return;
    darkmode.current = !darkmode.current;
  }

  const june21Day = 172;
  const june21Sunset = 1288 - 60;
  const june21Sunrise = 330 + 90;
  const dec21Day = 355;
  const dec21Sunset = 999 - 60;
  const dec21Sunrise = 492 + 90;
  const sunsetMinuteDiffPerDay = (june21Sunset - dec21Sunset) / (dec21Day - june21Day);
  const sunriseMinuteDiffPerDay = (dec21Sunrise - june21Sunrise) / (dec21Day - june21Day);

  function determineIfDarkmode(now) {
    const currentDayOfyear = Math.floor((now - new Date(now.getFullYear(), 0, 1)) / 86400000) + 1;
    const currentDayMinutes = now.getHours() * 60 + now.getMinutes();
    if (Math.abs(currentDayOfyear - june21Day) < Math.abs(currentDayOfyear - dec21Day)) {
      const currentDaySunset = june21Sunset - Math.floor(Math.abs(currentDayOfyear - june21Day) * sunsetMinuteDiffPerDay);
      const currentDaySunrise = june21Sunrise + Math.floor(Math.abs(currentDayOfyear - june21Day) * sunriseMinuteDiffPerDay);
      if (currentDayMinutes > currentDaySunset || currentDayMinutes < currentDaySunrise) darkmode.current = true;
      else darkmode.current = false;
    } else {
      const currentDaySunset = dec21Sunset + Math.floor(Math.abs(currentDayOfyear - dec21Day) * sunsetMinuteDiffPerDay);
      const currentDaySunrise = dec21Sunrise - Math.floor(Math.abs(currentDayOfyear - dec21Day) * sunriseMinuteDiffPerDay);
      if (currentDayMinutes > currentDaySunset || currentDayMinutes < currentDaySunrise) darkmode.current = true;
      else darkmode.current = false;
    }
  }

  const scrollTouchStartY = useRef(null);
  function scroll(event) {
    if (isWeekDayScrolling.current || isWeekDaySwiping.current) return;
    event.preventDefault(); // Stop default scrolling
    let deltaY;

    if (event.type === 'wheel') {
      deltaY = event.deltaY;
    } else if (event.type === 'touchmove') {
      deltaY = scrollTouchStartY.current - event.touches[0].clientY;
      scrollTouchStartY.current = event.touches[0].clientY;
    }

    if (Math.abs(deltaY) < 10) return;

    if (deltaY > 0) {
      // Scrolling down
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    } else if (deltaY < 0) {
      // Scrolling up
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  function toggleLandscapeFullscreen(event) {
    scrollTouchStartY.current = event.touches[0].clientY;
    let top;
    const body = document.querySelector('body');
    if (window.screen.orientation.type.includes("landscape") &&
    !document.fullscreenElement
    ){
      document.documentElement.requestFullscreen().catch(() => {});
      if (window.scrollY > (document.body.scrollHeight - window.innerHeight) / 2) top = 0;
      else top = 1;
      setTimeout(() => {
        window.scrollTo(top ? { top: 0 } : { top: document.body.scrollHeight });
      }, 100);
      body.style.touchAction = 'pan-y';
      handleTouchEnd();
    }
    else if (window.screen.orientation.type.includes("portrait") &&
    document.fullscreenElement
    ){
      document.exitFullscreen().catch(() => {});
      if (window.scrollY > (document.body.scrollHeight - window.innerHeight) / 2) top = 0;
      else top = 1;
      setTimeout(() => {
        window.scrollTo(top ? { top: 0 } : { top: document.body.scrollHeight });
      }, 100);
      body.style.touchAction = 'none';
      handleTouchEnd();
    }
  }

  const horizontalScrollStep = 16;
  const horizontalScrollAnimationStep = 1.25;
  const startTouch = useRef({});
  const horizontalScrollTouchReference = useRef({});
  const isWeekDayScrolling = useRef(false);
  const isWeekDayClick = useRef(false);
  const isWeekDaySwipe = useRef(false);
  const isWeekDaySwiping = useRef(false);
  const touchScrollDelta = useRef({ x: 0, y: 0});
  
  const handleTouchStart = (event) => {
    startTouch.current = {
      x: event.clientX,
      y: event.clientY
    };
    if (!weekSliceOpacitiesSet.current) return;
    const rushclockBounds = rushclockRef.current.getBoundingClientRect();
    const circleInCircleBounds = circleInCircleRef.current.getBoundingClientRect();
    const weekdaysBounds = weekdaysRef.current.getBoundingClientRect();
    const isInsideWeekdays = 
      event.clientX >= circleInCircleBounds.left &&
      event.clientX <= circleInCircleBounds.right &&
      event.clientY >= weekdaysBounds.top &&
      event.clientY <= weekdaysBounds.bottom;
    const isInsideRushclock = 
      event.clientX >= rushclockBounds.left &&
      event.clientX <= rushclockBounds.right &&
      event.clientY >= rushclockBounds.top &&
      event.clientY <= rushclockBounds.bottom;
    if (isInsideWeekdays) {
      isWeekDayClick.current = true;
      isWeekDayScrolling.current = true;
      horizontalScrollTouchReference.current = startTouch.current;
    } else if (isInsideRushclock) {
      isWeekDaySwipe.current = true;
    }
  }
  const handleTouchMove = (event) => {
    if (!isWeekDayClick.current &&
      !isWeekDayScrolling.current &&
      !isWeekDaySwipe.current
    ) return;
    const scrollRate = Math.min(window.innerWidth, window.innerHeight) / 100;
    const scrollDeltaX = (event.clientX - startTouch.current.x) / scrollRate;
    const scrollDeltaY = (event.clientY - startTouch.current.y) / scrollRate;
    if (isWeekDayScrolling.current && (Math.abs(scrollDeltaX) > 3 || Math.abs(scrollDeltaY) > 3)) isWeekDayClick.current = false;
    touchScrollDelta.current = {
      x: scrollDeltaX,
      y: scrollDeltaY
    }
    if (Math.abs(scrollDeltaX) > 5) isWeekDaySwiping.current = true;
    if (isWeekDayScrolling.current){
      let scrollX = event.clientX - horizontalScrollTouchReference.current.x;
      scrollX = Math.sign(scrollX) * Math.abs(scrollX);
      scrollValue.current += scrollX / scrollRate;
      horizontalScrollTouchReference.current = {
        x: event.clientX,
      };
    }
  };

  const handleTouchEnd = () => {
    if (
      isWeekDayClick.current &&
      !scrollValueSettleRequest.current
    ){
      isWeekDayScrolling.current = false;
      const circleInCircleBounds = circleInCircleRef.current.getBoundingClientRect();
      const circleCenterX = circleInCircleBounds.left + circleInCircleBounds.width / 2;
      if (startTouch.current.x > circleCenterX) {
        scrollValueTarget.current = scrollValue.current - horizontalScrollStep;
      } else {
        scrollValueTarget.current = scrollValue.current + horizontalScrollStep;
      }
      scrollValueSettleRequest.current = true;
    } else if (
      isWeekDaySwipe.current && 
      Math.abs(touchScrollDelta.current.x) > 5 &&
      Math.abs(touchScrollDelta.current.y) < 15 &&
      !scrollValueSettleRequest.current
    ){
      isWeekDayClick.current = false;
      if (touchScrollDelta.current.x > 1) {
        scrollValueTarget.current = scrollValue.current + horizontalScrollStep;
      } else {
        scrollValueTarget.current = scrollValue.current - horizontalScrollStep;
      }
      scrollValueSettleRequest.current = true;
    } else if (isWeekDayScrolling.current) {
      scrollValueTarget.current = Math.round(scrollValue.current / horizontalScrollStep) * horizontalScrollStep;
      scrollValueSettleRequest.current = true;
      isWeekDayScrolling.current = false;
    }
    isWeekDaySwipe.current = false;
    isWeekDaySwiping.current = false;
  }

  function getTimeStamp(s) {
    const absSeconds = Math.abs(Math.round(s));
  
    const hours = Math.min(Math.floor(absSeconds / 3600), 99);
    const minutes = Math.floor((absSeconds % 3600) / 60);
    const seconds = absSeconds % 60;
  
    const formattedTime = [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0')
    ].join(':');
  
    return formattedTime;
  }

  function getClockDegrees(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    const degrees = (totalSeconds / 86400) * 360;
    return degrees;
  }

  const today = useRef();
  useEffect(() => {
    if (weekSliceOpacitiesSet.current){
      scrollValueTarget.current = -(today.current - 3) * horizontalScrollStep;
      scrollValueSettleRequest.current = true;
    }
  }, [today.current]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const animationFrameId = useRef(null);
  function animate() {
    const date = new Date();
    const now = date / 1000;
    today.current = date.getDay();
    timeElapsed.current = {
      leftouttop : now - liveData.current?.leftouttop,
      leftmiddletop : now - liveData.current?.leftmiddletop,
      rightmiddletop : now - liveData.current?.rightmiddletop,
      rightouttop : now - liveData.current?.rightouttop,
      leftoutbottom : now - liveData.current?.leftoutbottom,
      leftmiddlebottom : now - liveData.current?.leftmiddlebottom,
      rightmiddlebottom : now - liveData.current?.rightmiddlebottom,
      rightoutbottom : now - liveData.current?.rightoutbottom
    }
    settimeElapsedState({
      leftouttop : getTimeStamp(liveData.current?.leftouttop > 0 ? timeElapsed.current.leftouttop : liveData.current?.leftouttop),
      leftmiddletop : getTimeStamp(liveData.current?.leftmiddletop > 0 ? timeElapsed.current.leftmiddletop : liveData.current?.leftmiddletop),
      rightmiddletop : getTimeStamp(liveData.current?.rightmiddletop > 0 ? timeElapsed.current.rightmiddletop : liveData.current?.rightmiddletop),
      rightouttop : getTimeStamp(liveData.current?.rightouttop > 0 ? timeElapsed.current.rightouttop : liveData.current?.rightouttop),
      leftoutbottom : getTimeStamp(liveData.current?.leftoutbottom > 0 ? timeElapsed.current.leftoutbottom : liveData.current?.leftoutbottom),
      leftmiddlebottom : getTimeStamp(liveData.current?.leftmiddlebottom > 0 ? timeElapsed.current.leftmiddlebottom : liveData.current?.leftmiddlebottom),
      rightmiddlebottom : getTimeStamp(liveData.current?.rightmiddlebottom > 0 ? timeElapsed.current.rightmiddlebottom : liveData.current?.rightmiddlebottom),
      rightoutbottom : getTimeStamp(liveData.current?.rightoutbottom > 0 ? timeElapsed.current.rightoutbottom : liveData.current?.rightoutbottom),
    });
    setclockRotationDeg(getClockDegrees(date));
    if (scrollValueSettleRequest.current) {
      if (Math.abs(scrollValueTarget.current - scrollValue.current) <= horizontalScrollAnimationStep) {
        scrollValue.current = Math.round(scrollValue.current / horizontalScrollStep) * horizontalScrollStep;
        scrollValueSettleRequest.current = false;
        isWeekDayScrolling.current = false;
        isWeekDayClick.current = false;
        isWeekDaySwipe.current = false;
        isWeekDaySwiping.current = false;
        touchScrollDelta.current = {x: 0, y: 0};
      } else {
        if (scrollValueTarget.current > scrollValue.current) {
          scrollValue.current += horizontalScrollAnimationStep;
        } else {
          scrollValue.current -= horizontalScrollAnimationStep;
        }
      }
    }
    const gradientOpacityError = targetConicGradientOpacity.current - conicGradientOpacity.current;
    if (Math.abs(gradientOpacityError) > 0.01){
      conicGradientOpacity.current += Math.sign(gradientOpacityError) * 0.015;
    } else conicGradientOpacity.current = targetConicGradientOpacity.current;
    animationFrameId.current = requestAnimationFrame(animate);
  }

  const cacheExpirySecondsKey = 'expirySecondsCache';
  const averageEstimateCacheKey = 'averageEstimateCache';
  const weekSliceOpacitiesCacheKey = 'weekSliceOpacitiesCache';
  const [started, setstarted] = useState(false);
  useEffect(() => {
    const handleDisconnected = () => setwebsocketStatus(0);
    const handleConnected = () => setwebsocketStatus(1);
    const handleReconnected = () => setwebsocketStatus(1);

    wsLink.subscriptionClient.onDisconnected(handleDisconnected);
    wsLink.subscriptionClient.onConnected(handleConnected);
    wsLink.subscriptionClient.onReconnected(handleReconnected);

    scrollValue.current = -(now.getDay() - 3) * horizontalScrollStep;
    determineIfDarkmode(now);
    window.addEventListener('wheel', scroll, { passive: false });
    window.addEventListener('touchmove', scroll, { passive: false });
    window.addEventListener('dblclick', toggleDarkmode);
    window.addEventListener('touchstart', toggleLandscapeFullscreen, { passive: false});
    window.addEventListener('touchend', handleTouchEnd);
    window.addEventListener('touchcancel', handleTouchEnd);
    window.addEventListener('pointerdown', handleTouchStart);
    window.addEventListener('pointermove', handleTouchMove);
    window.addEventListener('pointerup', handleTouchEnd);
    window.addEventListener('pointercancel', handleTouchEnd);
    setstarted(true);
    window.scrollTo({top: 0});
    const expirySecondsCache = localStorage.getItem(cacheExpirySecondsKey);
    const averageEstimateCache = localStorage.getItem(averageEstimateCacheKey);
    const weekSliceOpacitiesCache = localStorage.getItem(weekSliceOpacitiesCacheKey);
    if (!expirySecondsCache || 
        !averageEstimateCache || 
        !weekSliceOpacitiesCache ||
        (now / 1000) - Number(expirySecondsCache) > cacheExpirySeconds){
          fetchAverageData();
          fetchHistoryData();
          localStorage.setItem(cacheExpirySecondsKey, (now / 1000).toString());
          averageAndHistoryFetched.current = true;
        } else {
          averageEstimate.current = JSON.parse(averageEstimateCache);
          setTimeout(() => {
            setaverageEstimateState({
              averagewasherruntime: averageEstimate.current?.averagewasherruntime ? getTimeStamp(averageEstimate.current.averagewasherruntime) : 0,
              averagedryerruntime: averageEstimate.current?.averagedryerruntime ? getTimeStamp(averageEstimate.current.averagedryerruntime) : 0
            });
          }, 1000);
          setweekSliceOpacities(JSON.parse(weekSliceOpacitiesCache));
          setTimeout(() => {
            weekSliceOpacitiesSet.current = true;
          }, 1500);
        }
    animate();

    return () => {
      // Clean up event listeners
      window.removeEventListener('wheel', scroll);
      window.removeEventListener('touchmove', scroll);
      window.removeEventListener('dblclick', toggleDarkmode);
      window.removeEventListener('touchstart', toggleLandscapeFullscreen);
      window.removeEventListener('touchend', handleTouchEnd);
      window.removeEventListener('touchcancel', handleTouchEnd);
      window.removeEventListener('pointerdown', handleTouchStart);
      window.removeEventListener('pointermove', handleTouchMove);
      window.removeEventListener('pointerup', handleTouchEnd);
      window.removeEventListener('pointercancel', handleTouchEnd);

      // Cancel animation frame
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }

      // Remove WebSocket event listeners if possible
      if (wsLink.subscriptionClient.offDisconnected) {
        wsLink.subscriptionClient.offDisconnected(handleDisconnected);
      }
      if (wsLink.subscriptionClient.offConnected) {
        wsLink.subscriptionClient.offConnected(handleConnected);
      }
      if (wsLink.subscriptionClient.offReconnected) {
        wsLink.subscriptionClient.offReconnected(handleReconnected);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
    className="App"
    style={{
      transform: started ?
      `rotateX(0deg)`:
      `rotateX(89deg)`,
      opacity: started ?
      `1`:
      `0`
      }}>
      <div>
        <div 
          className={`live ${websocketStatus !== 0 && lData ? 'connected': ''}`}
          style={{
            backgroundColor: started ?
            ``:
            `black`
          }}/>
        <div className="grid">
          <div className="box">
            <img src={liveDataState?.leftouttop ? dryerframeRunning : dryerframeIdle} className="box-background" alt="dryerframe"/>
            <div
            className={`dryerdoor-background ${liveDataState?.leftouttop ? 'visible' : ''}`}
            style={{backgroundColor:
              `rgba(
                ${liveData.current?.leftouttop < 0 ? 255 : 0},
                ${liveData.current?.leftouttop > 0 ? Math.min((Math.pow(timeElapsed.current?.leftouttop / averageEstimate.current?.averagedryerruntime, 2)) * 255, 255) : 0},
                ${liveData.current?.leftouttop > 0 ? Math.max((1 - Math.pow(timeElapsed.current?.leftouttop / averageEstimate.current?.averagedryerruntime, 2)) * 255, 0) : 0},
                0.5
              )`
            }}
            />
            <img
              src={dryerDoor} 
              className={`dryerdoor ${liveDataState?.leftouttop ? liveData.current?.leftouttop > 0 ? 'running' : 'waiting' : ''}`} 
              style={liveData.current?.leftouttop > 0 ? {animation: `rotate ${Math.max(8 - (Math.pow(timeElapsed.current?.leftouttop / averageEstimate.current?.averagedryerruntime, 2)) * 6, 2)}s linear infinite`} : {rotate: `${Math.abs(liveData.current?.leftouttop) % 360}deg`}}
              alt="dryerdoor"/>
            <div className={`timeelapsed ${liveDataState?.leftouttop ? 'visible' : ''}`}>
              <strong>{timeElapsedState?.leftouttop}</strong>
            </div>
          </div>
          <div className="box">
            <img src={liveDataState?.leftmiddletop ? dryerframeRunning : dryerframeIdle} className="box-background" alt="dryerframe"/>
            <div
            className={`dryerdoor-background ${liveDataState?.leftmiddletop ? 'visible' : ''}`}
            style={{backgroundColor:
              `rgba(
                ${liveData.current?.leftmiddletop < 0 ? 255 : 0},
                ${liveData.current?.leftmiddletop > 0 ? Math.min((Math.pow(timeElapsed.current?.leftmiddletop / averageEstimate.current?.averagedryerruntime, 2)) * 255, 255) : 0},
                ${liveData.current?.leftmiddletop > 0 ? Math.max((1 - Math.pow(timeElapsed.current?.leftmiddletop / averageEstimate.current?.averagedryerruntime, 2)) * 255, 0) : 0},
                0.5
              )`
            }}
            />
            <img
              src={dryerDoor} 
              className={`dryerdoor ${liveDataState?.leftmiddletop ? liveData.current?.leftmiddletop > 0 ? 'running' : 'waiting' : ''}`} 
              style={liveData.current?.leftmiddletop > 0 ? {animation: `rotate ${Math.max(8 - (Math.pow(timeElapsed.current?.leftmiddletop / averageEstimate.current?.averagedryerruntime, 2)) * 6, 2)}s linear infinite`} : {rotate: `${Math.abs(liveData.current?.leftmiddletop) % 360}deg`}}
              alt="dryerdoor"/>
            <div className={`timeelapsed ${liveDataState?.leftmiddletop ? 'visible' : ''}`}>
              <strong>{timeElapsedState?.leftmiddletop}</strong>
            </div>
          </div>
          <div className="box">
            <img src={liveDataState?.rightmiddletop ? dryerframeRunning : dryerframeIdle} className="box-background" alt="dryerframe"/>
            <div
            className={`dryerdoor-background ${liveDataState?.rightmiddletop ? 'visible' : ''}`}
            style={{backgroundColor:
              `rgba(
                ${liveData.current?.rightmiddletop < 0 ? 255 : 0},
                ${liveData.current?.rightmiddletop > 0 ? Math.min((Math.pow(timeElapsed.current?.rightmiddletop / averageEstimate.current?.averagedryerruntime, 2)) * 255, 255) : 0},
                ${liveData.current?.rightmiddletop > 0 ? Math.max((1 - Math.pow(timeElapsed.current?.rightmiddletop / averageEstimate.current?.averagedryerruntime, 2)) * 255, 0) : 0},
                0.5
              )`
            }}
            />
            <img
              src={dryerDoor} 
              className={`dryerdoor ${liveDataState?.rightmiddletop ? liveData.current?.rightmiddletop > 0 ? 'running' : 'waiting' : ''}`} 
              style={liveData.current?.rightmiddletop > 0 ? {animation: `rotate ${Math.max(8 - (Math.pow(timeElapsed.current?.rightmiddletop / averageEstimate.current?.averagedryerruntime, 2)) * 6, 2)}s linear infinite`} : {rotate: `${Math.abs(liveData.current?.rightmiddletop) % 360}deg`}}
              alt="dryerdoor"/>
            <div className={`timeelapsed ${liveDataState?.rightmiddletop ? 'visible' : ''}`}>
              <strong>{timeElapsedState?.rightmiddletop}</strong>
            </div>
          </div>
          <div className="box">
            <img src={liveDataState?.rightouttop ? dryerframeRunning : dryerframeIdle} className="box-background" alt="dryerframe"/>
            <div
            className={`dryerdoor-background ${liveDataState?.rightouttop ? 'visible' : ''}`}
            style={{backgroundColor:
              `rgba(
                ${liveData.current?.rightouttop < 0 ? 255 : 0},
                ${liveData.current?.rightouttop > 0 ? Math.min((Math.pow(timeElapsed.current?.rightouttop / averageEstimate.current?.averagedryerruntime, 2)) * 255, 255) : 0},
                ${liveData.current?.rightouttop > 0 ? Math.max((1 - Math.pow(timeElapsed.current?.rightouttop / averageEstimate.current?.averagedryerruntime, 2)) * 255, 0) : 0},
                0.5
              )`
            }}
            />
            <img
              src={dryerDoor} 
              className={`dryerdoor ${liveDataState?.rightouttop ? liveData.current?.rightouttop > 0 ? 'running' : 'waiting' : ''}`} 
              style={liveData.current?.rightouttop > 0 ? {animation: `rotate ${Math.max(8 - (Math.pow(timeElapsed.current?.rightouttop / averageEstimate.current?.averagedryerruntime, 2)) * 6, 2)}s linear infinite`} : {rotate: `${Math.abs(liveData.current?.rightouttop) % 360}deg`}}
              alt="dryerdoor"/>
            <div className={`timeelapsed ${liveDataState?.rightouttop ? 'visible' : ''}`}>
              <strong>{timeElapsedState?.rightouttop}</strong>
            </div>
          </div>
          <div className="box">
            <img src={liveDataState?.leftoutbottom ? washerframeRunning : washerframeIdle} className="box-background" alt="washerframe"/>
            <div
            className={`washerdoor-background ${liveDataState?.leftoutbottom ? 'visible' : ''}`}
            style={{backgroundColor:
              `rgba(
                ${liveData.current?.leftoutbottom < 0 ? 255 : 0},
                ${liveData.current?.leftoutbottom > 0 ? Math.min((Math.pow(timeElapsed.current?.leftoutbottom / averageEstimate.current?.averagewasherruntime, 2)) * 255, 255) : 0},
                ${liveData.current?.leftoutbottom > 0 ? Math.max((1 - Math.pow(timeElapsed.current?.leftoutbottom / averageEstimate.current?.averagewasherruntime, 2)) * 255, 0) : 0},
                0.5
              )`
            }}
            />
            <img
              src={washerDoor} 
              className={`washerdoor ${liveDataState?.leftoutbottom ? liveData.current?.leftoutbottom > 0 ? 'running' : 'waiting' : ''}`} 
              style={liveData.current?.leftoutbottom > 0 ? {animation: `rotate ${Math.max(8 - (Math.pow(timeElapsed.current?.leftoutbottom / averageEstimate.current?.averagewasherruntime, 2)) * 6, 2)}s linear infinite`} : {rotate: `${Math.abs(liveData.current?.leftoutbottom) % 360}deg`}}
              alt="washerdoor"/>
            <div className={`timeelapsed ${liveDataState?.leftoutbottom ? 'visible' : ''}`}>
              <strong>{timeElapsedState?.leftoutbottom}</strong>
            </div>
          </div>
          <div className="box">
            <img src={liveDataState?.leftmiddlebottom ? washerframeRunning : washerframeIdle} className="box-background" alt="washerframe"/>
            <div
            className={`washerdoor-background ${liveDataState?.leftmiddlebottom ? 'visible' : ''}`}
            style={{backgroundColor:
              `rgba(
                ${liveData.current?.leftmiddlebottom < 0 ? 255 : 0},
                ${liveData.current?.leftmiddlebottom > 0 ? Math.min((Math.pow(timeElapsed.current?.leftmiddlebottom / averageEstimate.current?.averagewasherruntime, 2)) * 255, 255) : 0},
                ${liveData.current?.leftmiddlebottom > 0 ? Math.max((1 - Math.pow(timeElapsed.current?.leftmiddlebottom / averageEstimate.current?.averagewasherruntime, 2)) * 255, 0) : 0},
                0.5
              )`
            }}
            />
            <img
              src={washerDoor} 
              className={`washerdoor ${liveDataState?.leftmiddlebottom ? liveData.current?.leftmiddlebottom > 0 ? 'running' : 'waiting' : ''}`} 
              style={liveData.current?.leftmiddlebottom > 0 ? {animation: `rotate ${Math.max(8 - (Math.pow(timeElapsed.current?.leftmiddlebottom / averageEstimate.current?.averagewasherruntime, 2)) * 6, 2)}s linear infinite`} : {rotate: `${Math.abs(liveData.current?.leftmiddlebottom) % 360}deg`}}
              alt="washerdoor"/>
            <div className={`timeelapsed ${liveDataState?.leftmiddlebottom ? 'visible' : ''}`}>
              <strong>{timeElapsedState?.leftmiddlebottom}</strong>
            </div>
          </div>
          <div className="box">
            <img src={liveDataState?.rightmiddlebottom ? washerframeRunning : washerframeIdle} className="box-background" alt="washerframe"/>
            <div
            className={`washerdoor-background ${liveDataState?.rightmiddlebottom ? 'visible' : ''}`}
            style={{backgroundColor:
              `rgba(
                ${liveData.current?.rightmiddlebottom < 0 ? 255 : 0},
                ${liveData.current?.rightmiddlebottom > 0 ? Math.min((Math.pow(timeElapsed.current?.rightmiddlebottom / averageEstimate.current?.averagewasherruntime, 2)) * 255, 255) : 0},
                ${liveData.current?.rightmiddlebottom > 0 ? Math.max((1 - Math.pow(timeElapsed.current?.rightmiddlebottom / averageEstimate.current?.averagewasherruntime, 2)) * 255, 0) : 0},
                0.5
              )`
            }}
            />
            <img
              src={washerDoor} 
              className={`washerdoor ${liveDataState?.rightmiddlebottom ? liveData.current?.rightmiddlebottom > 0 ? 'running' : 'waiting' : ''}`} 
              style={liveData.current?.rightmiddlebottom > 0 ? {animation: `rotate ${Math.max(8 - (Math.pow(timeElapsed.current?.rightmiddlebottom / averageEstimate.current?.averagewasherruntime, 2)) * 6, 2)}s linear infinite`} : {rotate: `${Math.abs(liveData.current?.rightmiddlebottom) % 360}deg`}}
              alt="washerdoor"/>
            <div className={`timeelapsed ${liveDataState?.rightmiddlebottom ? 'visible' : ''}`}>
              <strong>{timeElapsedState?.rightmiddlebottom}</strong>
            </div>
          </div>
          <div className="box">
            <img src={liveDataState?.rightoutbottom ? washerframeRunning : washerframeIdle} className="box-background" alt="washerframe"/>
            <div
            className={`washerdoor-background ${liveDataState?.rightoutbottom ? 'visible' : ''}`}
            style={{backgroundColor:
              `rgba(
                ${liveData.current?.rightoutbottom < 0 ? 255 : 0},
                ${liveData.current?.rightoutbottom > 0 ? Math.min((Math.pow(timeElapsed.current?.rightoutbottom / averageEstimate.current?.averagewasherruntime, 2)) * 255, 255) : 0},
                ${liveData.current?.rightoutbottom > 0 ? Math.max((1 - Math.pow(timeElapsed.current?.rightoutbottom / averageEstimate.current?.averagewasherruntime, 2)) * 255, 0) : 0},
                0.5
              )`
            }}
            />
            <img
              src={washerDoor} 
              className={`washerdoor ${liveDataState?.rightoutbottom ? liveData.current?.rightoutbottom > 0 ? 'running' : 'waiting' : ''}`} 
              style={liveData.current?.rightoutbottom > 0 ? {animation: `rotate ${Math.max(8 - (Math.pow(timeElapsed.current?.rightoutbottom / averageEstimate.current?.averagewasherruntime, 2)) * 6, 2)}s linear infinite`} : {rotate: `${Math.abs(liveData.current?.rightoutbottom) % 360}deg`}}
              alt="washerdoor"/>
            <div className={`timeelapsed ${liveDataState?.rightoutbottom ? 'visible' : ''}`}>
              <strong>{timeElapsedState?.rightoutbottom}</strong>
            </div>
          </div>
        </div>
      </div>
      <div className="rushclock-container">
        <div 
          className="rushclock"
          ref={rushclockRef}
          style={{
          background: `conic-gradient(
            rgba(255, 0, 0, ${sliceOpacities[0] * conicGradientOpacity.current}) 0deg 7.5deg,
            rgba(255, 0, 0, ${sliceOpacities[1] * conicGradientOpacity.current}) 7.5deg 15deg,
            rgba(255, 0, 0, ${sliceOpacities[2] * conicGradientOpacity.current}) 15deg 22.5deg,
            rgba(255, 0, 0, ${sliceOpacities[3] * conicGradientOpacity.current}) 22.5deg 30deg,
            rgba(255, 0, 0, ${sliceOpacities[4] * conicGradientOpacity.current}) 30deg 37.5deg,
            rgba(255, 0, 0, ${sliceOpacities[5] * conicGradientOpacity.current}) 37.5deg 45deg,
            rgba(255, 0, 0, ${sliceOpacities[6] * conicGradientOpacity.current}) 45deg 52.5deg,
            rgba(255, 0, 0, ${sliceOpacities[7] * conicGradientOpacity.current}) 52.5deg 60deg,
            rgba(255, 0, 0, ${sliceOpacities[8] * conicGradientOpacity.current}) 60deg 67.5deg,
            rgba(255, 0, 0, ${sliceOpacities[9] * conicGradientOpacity.current}) 67.5deg 75deg,
            rgba(255, 0, 0, ${sliceOpacities[10] * conicGradientOpacity.current}) 75deg 82.5deg,
            rgba(255, 0, 0, ${sliceOpacities[11] * conicGradientOpacity.current}) 82.5deg 90deg,
            rgba(255, 0, 0, ${sliceOpacities[12] * conicGradientOpacity.current}) 90deg 97.5deg,
            rgba(255, 0, 0, ${sliceOpacities[13] * conicGradientOpacity.current}) 97.5deg 105deg,
            rgba(255, 0, 0, ${sliceOpacities[14] * conicGradientOpacity.current}) 105deg 112.5deg,
            rgba(255, 0, 0, ${sliceOpacities[15] * conicGradientOpacity.current}) 112.5deg 120deg,
            rgba(255, 0, 0, ${sliceOpacities[16] * conicGradientOpacity.current}) 120deg 127.5deg,
            rgba(255, 0, 0, ${sliceOpacities[17] * conicGradientOpacity.current}) 127.5deg 135deg,
            rgba(255, 0, 0, ${sliceOpacities[18] * conicGradientOpacity.current}) 135deg 142.5deg,
            rgba(255, 0, 0, ${sliceOpacities[19] * conicGradientOpacity.current}) 142.5deg 150deg,
            rgba(255, 0, 0, ${sliceOpacities[20] * conicGradientOpacity.current}) 150deg 157.5deg,
            rgba(255, 0, 0, ${sliceOpacities[21] * conicGradientOpacity.current}) 157.5deg 165deg,
            rgba(255, 0, 0, ${sliceOpacities[22] * conicGradientOpacity.current}) 165deg 172.5deg,
            rgba(255, 0, 0, ${sliceOpacities[23] * conicGradientOpacity.current}) 172.5deg 180deg,
            rgba(255, 0, 0, ${sliceOpacities[24] * conicGradientOpacity.current}) 180deg 187.5deg,
            rgba(255, 0, 0, ${sliceOpacities[25] * conicGradientOpacity.current}) 187.5deg 195deg,
            rgba(255, 0, 0, ${sliceOpacities[26] * conicGradientOpacity.current}) 195deg 202.5deg,
            rgba(255, 0, 0, ${sliceOpacities[27] * conicGradientOpacity.current}) 202.5deg 210deg,
            rgba(255, 0, 0, ${sliceOpacities[28] * conicGradientOpacity.current}) 210deg 217.5deg,
            rgba(255, 0, 0, ${sliceOpacities[29] * conicGradientOpacity.current}) 217.5deg 225deg,
            rgba(255, 0, 0, ${sliceOpacities[30] * conicGradientOpacity.current}) 225deg 232.5deg,
            rgba(255, 0, 0, ${sliceOpacities[31] * conicGradientOpacity.current}) 232.5deg 240deg,
            rgba(255, 0, 0, ${sliceOpacities[32] * conicGradientOpacity.current}) 240deg 247.5deg,
            rgba(255, 0, 0, ${sliceOpacities[33] * conicGradientOpacity.current}) 247.5deg 255deg,
            rgba(255, 0, 0, ${sliceOpacities[34] * conicGradientOpacity.current}) 255deg 262.5deg,
            rgba(255, 0, 0, ${sliceOpacities[35] * conicGradientOpacity.current}) 262.5deg 270deg,
            rgba(255, 0, 0, ${sliceOpacities[36] * conicGradientOpacity.current}) 270deg 277.5deg,
            rgba(255, 0, 0, ${sliceOpacities[37] * conicGradientOpacity.current}) 277.5deg 285deg,
            rgba(255, 0, 0, ${sliceOpacities[38] * conicGradientOpacity.current}) 285deg 292.5deg,
            rgba(255, 0, 0, ${sliceOpacities[39] * conicGradientOpacity.current}) 292.5deg 300deg,
            rgba(255, 0, 0, ${sliceOpacities[40] * conicGradientOpacity.current}) 300deg 307.5deg,
            rgba(255, 0, 0, ${sliceOpacities[41] * conicGradientOpacity.current}) 307.5deg 315deg,
            rgba(255, 0, 0, ${sliceOpacities[42] * conicGradientOpacity.current}) 315deg 322.5deg,
            rgba(255, 0, 0, ${sliceOpacities[43] * conicGradientOpacity.current}) 322.5deg 330deg,
            rgba(255, 0, 0, ${sliceOpacities[44] * conicGradientOpacity.current}) 330deg 337.5deg,
            rgba(255, 0, 0, ${sliceOpacities[45] * conicGradientOpacity.current}) 337.5deg 345deg,
            rgba(255, 0, 0, ${sliceOpacities[46] * conicGradientOpacity.current}) 345deg 352.5deg,
            rgba(255, 0, 0, ${sliceOpacities[47] * conicGradientOpacity.current}) 352.5deg 360deg
          )`,
          transform: `rotate(${-clockRotationDeg}deg)`
          }}
          >
          {[...Array(96)].map((_, index) => {
            return (
              <div
              key={index}
              className="clock-overlay"
              style={{ transform: `rotate(${index * 3.75}deg)` }}
              >
                <div 
                  className={index % 4 ? "quarter-label" : "hour-label"}
                  style={{
                    animation: historyPulseOpacities[Math.floor(index / 2)] > 0.75
                      ? `history-pulse-line 3.2s infinite` : ``
                  }}
                />
              </div>
            );
          })}
          {[...Array(24)].map((_, index) => (
            <div
            key={index}
            className="clock-overlay"
            style={{ transform: `rotate(${index * 15}deg)` }}
            >
              <div
              className="number-label"
              style={{
                transform: `rotate(${-index * 15 + clockRotationDeg}deg)`,
                animation: historyPulseOpacities[index * 2] > 0.75 || historyPulseOpacities[index * 2 - 1] > 0.75
                  ? `history-pulse-number 3.2s infinite` : ``
              }}
              >{index}
              </div>
            </div>
          ))}
        </div>
        <div
          ref={circleInCircleRef} 
          className="circleincircle"
          >
          <img src={dryer} className={`averageimage ${averageEstimateState?.averagedryerruntime ? 'dryer' : ''}`} alt="averageimagedryer"/>
          <div className={`averagestamp ${averageEstimateState?.averagedryerruntime ? 'dryer' : ''}`}>
            ~{averageEstimateState?.averagedryerruntime}
          </div>
          <img src={washer} className={`averageimage ${averageEstimateState?.averagewasherruntime ? 'washer' : ''}`} alt="averageimagewasher"/>
          <div className={`averagestamp ${averageEstimateState?.averagewasherruntime ? 'washer' : ''}`}>
            ~{averageEstimateState?.averagewasherruntime}
          </div>
          <div
          className={`weekdays ${weekSliceOpacitiesSet.current ? 'visible' : ''}`}
          ref={weekdaysRef}
          style={{ 
            transform: scrollValue.current >= 0 ? 
            `translateX(min(${scrollValue.current}vw, ${scrollValue.current}vh))`:
            `translateX(max(${scrollValue.current}vw, ${scrollValue.current}vh))`
          }}
          >
            {weekdaysMask.map((weekday, index) => (
              <div
              ref={(el) => (weekdayRefs.current[index] = el)}
              key={index}
              className="weekday"
              style={{color: (dayKeys[weekday] === today.current && today.current !== day.current && !scrollValueSettleRequest.current) ? `rgb(255, 0, 255)` : ``}}
              >
                {weekday}
              </div>
            ))}
          </div>
        </div>
        <div 
          className="now-label"
          style={{
            backgroundColor: today.current === day.current && weekSliceOpacitiesSet.current ?
            `rgb(255, 0, 255)`:
            `black`
          }}
        />
      </div>
    </div>
  );
}

const AppWrapper = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

export default AppWrapper;
